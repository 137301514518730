<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-03-25 09:35:05
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-28 11:15:09
 * @Description:
-->
<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="80%"
    class="qmDialog"
    @close="closeDialog"
  >
    <itemList v-if="showDialog" ref="itemList" :isIndex="false" dept-category="5,2" prjInfoState="1" @selectProject="rowSelect" :companyCode="companyCode" :phone="phone"></itemList>
  </el-dialog>
</template>

<script>
import itemList from "@/views/business/safe/itemList/index.vue";
export default {
  props:['companyCode','phone'],
  components: {
    itemList,
  },
  data() {
    return {
      showDialog: false,
      title: "",
    };
  },
  methods: {
    init() {
        this.showDialog = true;
        this.title = "选择项目";
    },
    // 选择公司
    rowSelect(row) {
        this.$emit('changeProject',row)
        this.closeDialog()
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>
