<template>
  <div v-if="previewShow">
    <el-form ref="dataForm" :model="dataForm" label-width="160px" :disabled="disabled">
      <div class="preview" v-for="(item, index) in formOption" :key="index">
        <div class="previewTitle">
          {{ item.groupName }}
        </div>
        <el-row style="margin-top: 12px">
          <el-col
            :span="item.span"
            v-for="(items, indexs) in item.properties"
            :key="indexs"
          >
            <el-form-item
              :label="items.fieldName"
              :prop="items.fieldCode"
              :rules="[
                {
                  required: items.isEnable == '1' ? true : false,
                  message: items.inputPrompt || items.fieldName + '不能为空',
                },
              ]"
            >
              <el-input
                v-if="items.type == '1'"
                type="number"
                @blur="inputParamDefaultVal(items.fieldCode)"
                @input="validateNumber(items.fieldCode)"
                v-model.trim="dataForm[items.fieldCode]"
                :placeholder="items.inputPrompt || '请输入' + items.fieldName"
              >
                <template slot="append">
                  {{ unitItem[items.unit] }}
                </template>
              </el-input>
              <el-input
                v-if="items.type == '2' "
                type="number"
                @blur="inputParamDefaultVal(items.fieldCode)"
                @input="validateNumber(items.fieldCode)"
                :disabled="items.inputWay == '2'"
                v-model.trim="dataForm[items.fieldCode]"
                :placeholder="items.inputPrompt || '请输入' + items.fieldName"
              >
                <template slot="append">
                  {{ unitItem[items.unit] }}
                </template>
              </el-input>
              <el-input
                v-if="items.type == '3' "
                type="text"
                :maxlength="items.inputLength || 100"
                v-model="dataForm[items.fieldCode]"
                :placeholder="items.inputPrompt || '请输入' + items.fieldName"
              >
                <template slot="append">
                  {{ unitItem[items.unit] }}
                </template>
              </el-input>
              <el-date-picker
                v-if="items.type == '4'"
                v-model="dataForm[items.fieldCode]"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :placeholder="items.inputPrompt || '请选择' + items.fieldName"
              >
              </el-date-picker>
              <el-select v-model="dataForm[items.fieldCode]" ref="select"
                         :placeholder="items.inputPrompt || '请选择' + items.fieldName"
                         v-if="items.type == '5'">
                <el-option
                  v-for="dic in items.dictionaryOption"
                  :key="dic.dictKey"
                  :label="dic.dictValue"
                  :value="dic.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataForm: {},
      type: '',
      previewShow: false,
      formOption: [],
      disabled: false,
      unitItem: {
        1: "元",
        2: "天"
      }
    };
  },
  watch: {
    'items.fieldCode': 'calculateResult',

  },
  methods: {
    calculateResult() {
      console.log("进行输出*--*")
    },
    validateNumber(fileName) {
      let value = this.dataForm[fileName];
      if (value.length > 11) value = value.slice(0, 11);
      this.dataForm[fileName] = value
    },
    inputParamDefaultVal(code) {
      let amountList = new Array();
      this.formOption.forEach((item) => {
        item.properties.forEach((items) => {
          if (items.inputWay == 2) {
            amountList.push(items)
          }
        })
      })
      let that = this
      // for (let i = 1; i <= amountList.length ; i++) {
      console.log("进行输出",amountList)
      amountList.forEach((item) => {
        if(item.calculationRules.includes(code)){
          this.officialValueTree(item, that.dataForm, amountList)
        }
        // this.officialValueTree(item, that.dataForm, amountList)

        // // 进行公式解析
        // var createElement = this.render(item.calculationRules, that.dataForm);
        // console.log("进行输出---------",createElement)
        // // 字符串转计算公式
        // var eval1 = eval(createElement);
        // // 计算数据
        // let number = this.keepTwoDecimalFull(eval1);
        // // 赋值
        // that.dataForm[item.fieldCode] = number;
      })
      // }/

    },
    officialValueTree(amount, dataForm, amountList) {
      var createElement = this.render(amount.calculationRules, dataForm);
      if(createElement.includes("undefined")){
        return
      }
      // this.officialValueTree(amountList[i])
      // 字符串转计算公式
      var eval1 = eval(createElement);
      // 计算数据
      let number = this.keepTwoDecimalFull(eval1);
      // 赋值
      dataForm[amount.fieldCode] = number;
      for (let i = 0; i < amountList.length; i++) {
        if(amountList[i].calculationRules.includes(amount.fieldCode)){
          this.officialValueTree(amountList[i], dataForm, amountList)
        }
      }
    },
    extract(data) {
      const formula = data;
      const variableRegex = /#\{([^\}]+)\}/g;
      const variables = [];
      let match;
      while ((match = variableRegex.exec(formula))) {
        const variable = match[1];
        variables.push(variable);
      }
      return variables
    },
    // 判断传入的对象是否为空值
    getValue(obj) {
      for (var key in obj) {
        if (!Boolean(obj[key])) {
          return false;
        }
      }
      return true;
    },
    // 进行解析公式
    render(template, data) {
      var orgTem = "0"
      if (typeof template != "undefined") {
        const regex = /\#{[^{]+}/g;
        orgTem = template.replace(regex, (match) => {
          const path = match.slice(2, -1).trim();
          return data[path]
        });
      }
      return orgTem
    },
    // 计算公式计算
    keepTwoDecimalFull(num) {
      var result = parseFloat(num);
      if (isNaN(result)) {
        return;
      }
      Math.sign(num) == 1 ? result = Math.round(num * 100) / 100 : result = Math.round(num * 1000) / 1000
      var s_x = result.toString();
      var pos_decimal = s_x.indexOf('.');
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += '.';
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += '0';
      }
      return s_x;
    },

  },
};
</script>
<style lang="scss" scoped>
.preview {
  width: 100%;
}

.previewTitle {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

/* input为number时去掉对应的上下箭头 */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

</style>
