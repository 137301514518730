<template>
    <el-container class="itemList" v-if="userInfo.deptCategory != 5">
      <CommonTree
        ref="commonTree"
        treeTitle="组织列表"
        :defaultProps="defaultProps"
        :showCheckbox="false"
        @getNodeClick="treeNodeClick"
        :isShowdig="false"
        :treeExpand="false"
        :isZoom="true"
        :handle-data="filterData"
        :show="show"
        @showChange="showChange"
        :urlParmas="{
          tenantId: '',
          deptCategory:deptCategory ||  '2',
          parentId: userInfo.dept_id,
        }"
        node-key="id"
      ></CommonTree>

      <el-main>
        <head-layout
          head-title="项目列表"
          :head-btn-options="headBtnOptions"
          @head-add="handleExport"
          @head-quote-project="handleQuoteProject"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          v-model="searchForm"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          @grid-row-detail-click="rowEdit"
          @gird-handle-select-click="selectionChange"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{ row }">
<!--            <div v-if="isIndex">-->
              <el-button
                style="margin: 0 3px"
                type="text"
                size="small"
                @click="rowEdit(row, 'edit')"
                >编辑
              </el-button>
<!--              <el-button-->
<!--                style="margin: 0 3px"-->
<!--                type="text"-->
<!--                size="small"-->
<!--                @click="rowClose(row)"-->
<!--                >{{ row.prjState === 0 ? "执行" : "关闭" }}-->
<!--              </el-button>-->
<!--            </div>-->
<!--            <el-button-->
<!--              v-if="!isIndex"-->
<!--              style="margin: 0 3px"-->
<!--              type="text"-->
<!--              size="small"-->
<!--              @click="selectRow(row)"-->
<!--              >选择</el-button-->
<!--            >-->
          </template>
        </grid-layout>
      </el-main>
      <selectQuoteProject
        ref="selectQuoteProject"
        @callback="quoteProjectCallback"
      ></selectQuoteProject>
    </el-container>
</template>
<script>
import CommonTree from "@/views/components/com_tree_new";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import selectQuoteProject from "./selectQuoteProject.vue";
import { prjbasicinfoPage, prjbasicinfoSubmit } from "@/api/safe/itemList";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";
import { mapGetters } from "vuex";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    selectQuoteProject,
  },
  props: {
    isIndex: {
      type: Boolean,
      default: true,
    },
    companyCode: {
      type: String,
      default: "",
    },
    prjInfoState: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    deptCategory: {
      type: String,
      default: "",
    },
    // 第三方人员根据人员电话来查项目
    phone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchForm: {},
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      searchTitle: "title",
      query: {},
      selectionList: [],
      tableData: [],
      tableLoading: true,
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "项目名称",
          prop: "prjCode",
          span: 3,
          placeholder: "项目名称或编号",
        },
        {
          label: "项目状态",
          prop: "prjState",
          span: 2,
          dataType: "number",
          type: "select",
          placeholder: "请选择项目状态",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_stage`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "所属板块",
          prop: "prjType",
          span: 3,
          type: "select",
          placeholder: "请选择所属板块",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=biz_sector`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "境内外",
          prop: "isDomestic",
          span: 2,
          type: "select",
          placeholder: "请选择境内外",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=domestic_overseas`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "纳入安全管理",
          prop: "prjInfoState",
          span: 3,
          type: "select",
          placeholder: "请选择纳入安全管理",
          dicUrl: `/api/sinoma-system/dict/dictionary?code=yes_no`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      isFirst: true,
      show: false,
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      return this.isIndex
        ? [
            {
              label: "导出",
              emit: "head-add",
              type: "button",
              icon: "",
              btnOptType: "primary",
            },
            {
              label: "引入",
              emit: "head-quote-project",
              type: "button",
              icon: "",
              btnOptType: "primary",
            },
          ]
        : [];
    },
    tableOption() {
      return {
        selection: this.isIndex, // isIndex == true 表示是当前项目列表页面 == false 表示从黑名单组织或者黑名单人员中点击弹框进入
        linklabel: this.isIndex ? "prjCode" : "",
        column: [
          {
            label: "项目编码",
            prop: "prjCode",
            align: "left",
            overHidden: true,
            minWidth: 200,
          },
          {
            label: "项目名称",
            prop: "prjName",
            align: "left",
            overHidden: true,
            minWidth: 200,
          },
          {
            label: "项目简称",
            prop: "prjAs",
            align: "left",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "执行单位",
            prop: "contractName",
            align: "left",
            minWidth: 150,
            overHidden: true,
          },

          {
            label: "项目状态",
            prop: "stage",
            dataType: "number",
            align: "center",
            minWidth: 80,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_stage`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "纳入安全管理",
            prop: "prjInfoState",
            align: "center",
            dataType: "number",
            minWidth: 160,
            dicUrl: `/api/sinoma-system/dict/dictionary?code=yes_no`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "区域",
            prop: "areName",
            align: "center",
            minWidth: 80,
            overHidden: true,
          },
          {
            label: "所属板块",
            prop: "prjType",
            dataType: "number",
            align: "center",
            minWidth: 80,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=biz_sector`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "境内外",
            prop: "isDomestic",
            dataType: "number",
            align: "center",
            minWidth: 80,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=domestic_overseas`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "入网日期",
            prop: "prjJoinDate",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "立项日期",
            prop: "prjDate",
            minWidth: 120,
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },

  mounted() {
    // console.log(this.$route.params)
    let prjInfoState=this.prjInfoState || this.$route.query.prjInfoState || ''
    this.searchForm.prjState = String(this.$route.query.prjState || '') ;
    this.searchForm.prjInfoState = String(prjInfoState);
    this.searchForm.phone = this.phone ||'';
    this.searchForm.userId = this.userId ||'';
    this.searchForm.companyCode = this.companyCode ||'';
    if (this.userInfo.deptCategory == 5 || this.userInfo.deptCategory == 3) {
      this.$router.$avueRouter.closeTag();
      this.rowEdit({ id: this.userInfo.company_id }, "view", true);
    } else {
      this.onLoad(this.page, {});
    }
  },
  methods: {
    showChange(v) {
      this.show = v;
    },
    handleQuoteProject() {
      if (!this.query.id || this.query.deptCategory != 2) {
        this.$message.warning("请先选择公司");
      } else {
        this.$refs.selectQuoteProject.init(
          { excludeBound: true },
          this.query.id
        );
      }
    },
    quoteProjectCallback() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 搜索
    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 清空
    gridHeadEmpty() {
      this.page.currentPage = 1;
      this.searchForm = {};
      this.onLoad(this.page);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    filterData(data){
      return this.deptCategory ? this.recursivefilter(data) : data
    },
    recursivefilter(arr, value) {
      return arr.filter(item => {
        if (item.deptCategory == 5) {
          return false
        }
        if (item.children && item.children.length > 0) {
          item.children = this.recursivefilter(item.children)
        }
        return true
      })
    },
      treeNodeClick(node) {
      this.query = node;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 获取列表数据
    onLoad(page) {
      this.page = page;
      this.tableLoading = true;

      prjbasicinfoPage(
        page.currentPage,
        page.pageSize,
        Object.assign(this.searchForm, {
          contractId: this.query.id,
        })
      ).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
          this.isFirst = false;
        }
      });
    },
    // 编辑
    rowEdit(row, type, isMenu) {
      this.$router.push({
        path: `/synthesis/index`,
        query: {
          id: row.id,
          type: type || "view",
          isMenu, // 是否是当前登录用户切换成项目时，点击项目设置菜单直接进入查看项目详情页面 没有取消按钮和选择备案单位
        },
      });
    },

    // 关闭
    rowClose(row) {
      let queryData = {
        id: row.id,
        prjState: row.prjState === 0 ? 1 : 0,
      };
      let title = row.prjState === 0 ? "确认执行此数据?" : "确认关闭当前项目?";
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        prjbasicinfoSubmit(queryData).then((res) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.onLoad(this.page, {});
        });
      });
    },
    // 导出
    handleExport() {
      this.$confirm("是否导出项目列表数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/prjbasicinfo/export?${
            this.website.tokenHeader
          }=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, `项目列表${dateNow()}.xlsx`);
        });
      });
    },
    // 弹窗选择回显
    selectRow(row) {
      this.isDiallog = false;
      this.$emit("selectProject", row);
    },
  },
};
</script>
<style>
.itemList{
  position: relative
}
</style>
