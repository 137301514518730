var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userInfo.deptCategory != 5
    ? _c(
        "el-container",
        { staticClass: "itemList" },
        [
          _c("CommonTree", {
            ref: "commonTree",
            attrs: {
              treeTitle: "组织列表",
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
              isShowdig: false,
              treeExpand: false,
              isZoom: true,
              "handle-data": _vm.filterData,
              show: _vm.show,
              urlParmas: {
                tenantId: "",
                deptCategory: _vm.deptCategory || "2",
                parentId: _vm.userInfo.dept_id,
              },
              "node-key": "id",
            },
            on: { getNodeClick: _vm.treeNodeClick, showChange: _vm.showChange },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "项目列表",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-add": _vm.handleExport,
                  "head-quote-project": _vm.handleQuoteProject,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
                model: {
                  value: _vm.searchForm,
                  callback: function ($$v) {
                    _vm.searchForm = $$v
                  },
                  expression: "searchForm",
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOption,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowEdit,
                  "gird-handle-select-click": _vm.selectionChange,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "customBtn",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 3px" },
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowEdit(row, "edit")
                                },
                              },
                            },
                            [_vm._v("编辑\n              ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2450153340
                ),
              }),
            ],
            1
          ),
          _c("selectQuoteProject", {
            ref: "selectQuoteProject",
            on: { callback: _vm.quoteProjectCallback },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }